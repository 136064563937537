//User reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';

//UI Reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS'; 
export const LOADING_DATA = 'LOADING_DATA';
export const STOP_LOADING_UI = 'STOP_LOADING_UI'
//data reduceer types
export const SET_SCREAMS = 'SET_SCREAMS';
export const SET_SCREAM = 'SET_SCREAM';
export const LIKE_SCREAM = 'LIKE_SCREAM';
export const UNLIKE_SCREAM = 'UNLIKE_SCREAM';
export const DELETE_SCREAM = 'DELETE_SCREAM';
export const POST_SCREAM = 'POST_SCREAM';
export const SUBMIT_COMMENT = 'SUBMIT_COMMENT';
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ';
export const SEND_A_CHAT = 'SEND_A_CHAT';
export const SET_CHATS = 'SET_CHATS';